import Vue from 'vue'
import Router from 'vue-router'
import vueRoutes from 'site/config/vue-routes'
import {addClass, removeClass} from 'shared/utils/utils'
import store from 'site/store'
import types from 'site/store/types'

Vue.use(Router)

//handle scroll with our code, not the default browser setup or vue.js
if (typeof history.scrollRestoration !== 'undefined') {
	history.scrollRestoration = 'manual'
}

let router = new Router({
	base: '/',//make everything under /dashboard
	routes: vueRoutes,
	mode: 'history',  //remove # in the url bar,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash
				// , offset: { x: 0, y: 10 }
			}
		} else if (savedPosition) {
			return savedPosition
		} else {
			return {x: 0, y: 0}
		}
	}
})


router.beforeEach((to, from, next) => {
	// store.dispatch('startPageLoader')
	let isFirstLoad = from.name === null
	if (isFirstLoad) {

		let toRouteId = to.meta ? to.meta.id : null

		let otherRoutes = vueRoutes.reduce((ids, route) => {
			let currentRouteId = route.meta ? route.meta.id : null
			if (currentRouteId !== null && currentRouteId !== toRouteId) {
				ids.push(currentRouteId)
			}
			return ids
		}, [])

		store.dispatch(types.PRELOAD_PAGES, otherRoutes)
	}

	if (to.meta.id) {
		store.dispatch(types.LOAD_PAGE, to.meta.id)
			.then(() => {
				// store.dispatch('stopPageLoader')
				next()
			})
			.catch((e) => {
				next()
			})
	} else {
		next()
	}


})

router.afterEach((to, from) => {


	setTimeout(() => {
		removeClass(document.body, `page-${from.name}`)
		addClass(document.body, `page-${to.name}`)

		if (to.name !== 'homepage') {
			addClass(document.body, `page-not-homepage`)
		}
		if (to.name === 'homepage') {
			removeClass(document.body, `page-not-homepage`)
		}
	}, 400)//Time to the fade out to work


	if (typeof window.gtag !== 'undefined') {
		window.gtag('event', 'page_view', {'page_path': window.location.pathname})
	}
})

window.vueRouter = router

export default router
