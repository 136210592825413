<template>
	<div class="app">
		<vue-header></vue-header>
		<div class="vue-router-container">
			<div class="vue-router-inner-container">
				<transition
					name="fade"
					mode="out-in"
				>
					<router-view/>
				</transition>
			</div>
		</div>
		<vue-footer></vue-footer>

	</div>
</template>
<script>
	import VueFooter from 'shared/components/atoms/Footer'
	import VueHeader from 'site/components/headers/VueHeader'
	export default {
		name:'app',
		components: {VueHeader, VueFooter},
	    metaInfo() {
	        return {
	            title: 'éclo, plateforme d’aide au développement du language chez l’enfant',
	            titleTemplate: `%s – éclo`
	        }
	    }
	}
</script>
