<template>
	<div class="row start-xs">
		<div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
			<div class="heading--content my-medium" :class="classes">
				<h1 class="mb-x-small text-blue">{{ title }}</h1>
				<p v-html="description"></p>
			</div>
		</div>
	</div>

</template>

<script>
	export default {
		name: 'page-heading',
		props: {
			description: String,
			title: String,
			classes: String
		}
	}
</script>
