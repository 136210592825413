import Vue from 'vue'
import VueResource from 'vue-resource'
import VueMeta from 'vue-meta'
import VueAnalytics from 'vue-analytics';

import router from 'site/vue-config/router'
import store from 'site/store'

import 'shared/config/filters'
import 'shared/vendors/moment-fr'

import App from 'site/components/App'

import 'shared/config/mixins'

import 'site/assets/scss/site.scss'

//global components
import BrushEffect from 'shared/components/utils/BrushEffect'
import UnderlineGreenBrush from 'shared/components/utils/UnderlineGreenBrush'

//import vendors
import gsap from 'shared/vendors/gsap'
import Plyr from 'shared/vendors/Plyr'

Vue.use(VueResource)

// Meta descriptions
Vue.use(VueMeta, {})

// Analytics
const isProd = process.env.NODE_ENV === 'production';
Vue.use(VueAnalytics, {
	id: process.env.VUE_APP_SITE_GOOGLE_ANALYTICS_ID,
    router,
    debug: {
        enabled: !isProd,
        sendHitTask: isProd,
    },
});

Vue.component(BrushEffect.name, BrushEffect)
Vue.component(UnderlineGreenBrush.name, UnderlineGreenBrush)

Vue.config.productionTip = false
Vue.http.headers.common['Content-Type'] = 'application/x-www-form-urlencoded'

//mount the app on the specific div#eclo-app
const app = new Vue({
	el: '#eclo-site',
	router: router,
	store: store,
	components:{
		App, UnderlineGreenBrush, BrushEffect
	},
	mounted() {
		let body = document.querySelector('body')
		body.className = body.className.replace('no-js', '') + ' js ready'
	}
})


//exposed Vue
window.Vue = Vue

export default app
