<template>
	<section id="page-not-ready" class="page-not-ready">
		<div class="page-header mb-large">
			<div class="container-fluid">
				<div class="flex flex-row start-xs">
					<div class="col-xxs-12 col-xs-12 col-sm-10 col-md-8 col-lg-8 col-xlg-8 page-header--title">
						<h1>Contenu à venir, profitez-en pour compléter le questionnaire</h1>
					</div>
				</div>
			</div>
		</div>

		<homepage-cta></homepage-cta>
	</section>
</template>

<script>
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	export default {
		name:'page-not-ready',
		components: {HomepageCta}
	}
</script>
