<template>
	<header role="banner" class="header desktop-header">
		<div class="container-fluid full-width">
			<div class="row between-xs middle-xs no-gutter header-menu-container">
				<div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xlg-5">
					<ul class="menu menu--left">
						<li>
							<router-link to="/a-propos" class="btn btn-menu-brush btn-header menu-item">
								<brush-btn type="btn-medium">
									<span class="title">À&nbsp;propos</span>
								</brush-btn>
							</router-link>
						</li>
						<li>
							<router-link to="/ressources" class="btn btn-menu-brush btn-header menu-item">
								<brush-btn type="btn-medium">
									<span class="title">Ressources</span>
								</brush-btn>
							</router-link>
						</li>
					</ul>
				</div>
				<div class="col-xs-4 col-sm-2 col-md-2 col-lg-2 col-xlg-2 logo-wrapper">
					<router-link class="h-large logo block mx-auto" to="/">
						<inline-svg id="eclo-logo"></inline-svg>
					</router-link>
				</div>
				<div class="col-xs-12 col-sm-5 col-md-5 col-lg-5 col-xlg-5 desktop-header-hide">
					<ul class="menu menu--right">
            <li>
							<a :href="`${ECLO_APP_URL}/`" title="Questionnaire"
							   class="btn btn-menu-brush btn-header active menu-item">
								<brush-btn type="btn-medium">
									Accéder à l’information
								</brush-btn>
							</a>
						</li>
					</ul>
				</div>
			</div>
			<div class="row between-xs no-gutter header-menu-mobile-container">
				<div class="col-xs-6 col-sm-3 col-md-3 col-lg-3">
					<router-link to="/" class="h-normal inline-block logo-link">
						<inline-svg id="eclo-logo"></inline-svg>
					</router-link>
				</div>


				<div class="col-xs-6 col-sm-3 col-md-3 col-lg-3 end-xs">

					<div
						class="flex flex-row middle-xs end-xs hamburger-menu-container"
						@click.prevent="toggleSidebarMenu"
						:class="{'open': sidebarMenuOpen}"
					>
						<h4 v-if="sidebarMenuOpen" class="menu-item">Fermer</h4>
						<h4 v-else class="menu-item">Menu</h4>


						<round-btn size="normal">
							<div class="hamburger-menu">
								<span>
									<sprite id="line-horizontal-xsmall"></sprite>
								</span>
								<span>
									<sprite id="line-horizontal-xsmall"></sprite>
								</span>
								<span>
									<sprite id="line-horizontal-xsmall"></sprite>
								</span>
							</div>
						</round-btn>

					</div>
				</div>


			</div>
		</div>

		<sidebar-header
			v-on:close="sidebarMenuOpen = false"
			v-on:open="sidebarMenuOpen = true"
		></sidebar-header>

	</header>


</template>
<script>
	import {dynamicRoutes, routes} from 'app/config/routes'
	import {toggleClass, addClass, removeClass, isDescendant, isEmpty} from 'shared/utils/utils'
	import InlineSvg from 'shared/components/utils/InlineSvg'
	import Sprite from 'shared/components/utils/Sprite'
	import RoundBtn from 'shared/components/atoms/btns/RoundBtn'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import {EventBus, EVENTS} from 'shared/event-bus'
	import QuestionsHeader from 'app/components/headers/QuestionsHeader'
	import SidebarHeader from 'shared/components/headers/SidebarHeader'

	const headerAnimationS = 0.4

	export default {
		name: 'vue-header',
		components: {SidebarHeader, QuestionsHeader, BrushBtn, RoundBtn, Sprite, InlineSvg},
		data: function () {
			return {
				currentSelectedQuestion: '',
				sidebarMenuOpen: false
			}
		},
		created() {
			this.currentHashOnScreen = this.currentHashOnScreen.bind(this)

			EventBus.$on(EVENTS.CURRENT_HASH_ON_SCREEN, this.currentHashOnScreen)
		},
		computed: {
			headerType() {
				switch (this.$route.name) {
					case 'dashboard':
						return 'dashboard'
					case 'strategy':
						return 'dashboard'
					case 'questions':
						return 'resultQuestion'
					case 'result':
						return 'resultFinal'
					default:
						return 'default'
				}
			},
			result() {
				if (typeof this.$route.params.resultUID === 'undefined') {
					return null
				}
				//Get Result from ' URL
				return this.$store.getters.getResultByUID(this.$route.params.resultUID)
			},
			canAccessResult() {
				if (!this.result) return
				return this.result.isComplete
			}
		},
		methods: {
			goToQuestionHash(hash) {
				this.$router.push(hash)
				this.currentHashOnScreen(hash)

			},
			currentHashOnScreen(hash) {
				if (this.headerType === 'resultFinal') {
					this.currentSelectedQuestion = hash
				}
			},
			toggleSidebarMenu() {
				EventBus.$emit(EVENTS.TOGGLE_SIDEBAR_MENU)
			}
		}
	}
</script>
