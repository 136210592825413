import ajax from 'shared/utils/ajax'
import types from 'site/store/types'

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
	routes: initData.routes,
	pages: initData.pages || {}
}

// getters, make function easy to access by vue
//instead
const getters = {}

// actions
const actions = {
	[types.PRELOAD_PAGES](store, pagesIds) {
		pagesIds.forEach(id => store.dispatch(types.LOAD_PAGE, id))
	},
	[types.LOAD_PAGE](store, pageId) {

		if (typeof store.state.pages[pageId] !== 'undefined') {
			return Promise.resolve(store.state.pages[pageId])
		}

		return new Promise((resolve, reject) => {
			let url = `/actions/eclo-app-module/page/get-page?id=${encodeURI(pageId)}`

			ajax({
				url: url,
				method: 'get',
			})
				.then(data => {
					if (typeof data.page === 'undefined') {
						return reject()
					}

					store.commit(types.LOAD_PAGE, data.page)
					resolve(data)
				})
				.catch(err => {
					//store.commit('showError', err)
					reject()
				})
		})

	},

}

// mutations
const mutations = {
	[types.LOAD_PAGE](state, pageData) {
		state.pages[pageData.id] = pageData
	}
}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
