<template>

	<section id="ressources-page" v-if="pageData">

		<div class="page-header mb-large">

			<div class="container-fluid">
				<div class="row start-xs">
					<div class="col-xxs-12 col-sm-10 col-lg-8 page-header--title">
						<dynamic :template="fields.body"></dynamic>
					</div>
				</div>
			</div>

		</div>

		<div class="container-fluid">
			<div class="row start-xs">
				<h4 class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 font-size-14">
					Filtrer les ressources</h4>

				<div class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 filters form">
					<div class="row flex--wrap flex--justify-start">
						<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<div class="input-container my-small">
								<div class="select-container">
									<select @change.prevent="changeRegion" v-model="regionId" class="reverse">
										<option value="*">Région</option>
										<option
											v-for="region in orderedRegions"
											:key="region.id"
											:value="region.id"
										>{{region.title}}
										</option>
									</select>
								</div>
							</div>
						</div>
						<div class="col-xs-12 col-sm-4 col-md-4 col-lg-4">
							<div class="input-container my-small">
								<div class="select-container">
									<select @change.prevent="changeCategory" v-model="catId" class="reverse">
										<option value="*">Secteur d'activité</option>
										<option
											v-for="cat in $store.state.ressources.categories"
											:key="cat.id"
											:value="cat.id"
										>{{cat.title}}
										</option>
									</select>
								</div>
							</div>
						</div>
					</div>

					<span class="line">
						<sprite id="line-horizontal-xxlarge"></sprite>
					</span>
				</div>
			</div>
		</div>


		<div class="container-fluid">
			<loading-container loader-name="ressources">
			</loading-container>
			<div class="row start-xs" id="filter-items"
				 :class="{'loading': $store.state.general.loaderName.includes('ressources')}"
			>
				<div
					v-for="ressource in ressources"
					:key="ressource.id"
					class="col-xxs-12 col-xs-6 col-sm-6 col-md-4 col-lg-4 mt-medium article-wrapper"
				>
					<ressource-card :ressource="ressource"></ressource-card>
				</div>
			</div>

			<div class="row center-xs pagination" v-if="loadMore">
				<div class="col-xxs-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xlg-6 mt-medium">
					<button
						@click.prevent="loadMoreBtn"
						type="button" class="btn btn-default-brush next load-more">
						<brush-btn type="btn-xxlarge">
							<span>Afficher plus de résultats</span>
						</brush-btn>
					</button>
				</div>
			</div>
		</div>

		<homepage-cta></homepage-cta>

	</section>


</template>
<script>
	import BlogCard from 'site/components/atoms/card/BlogCard'
	import Sprite from 'shared/components/utils/Sprite'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	import LoadingContainer from 'shared/components/container/LoadingContainer'
	import types from 'site/store/types'
	import RessourceCard from 'site/components/atoms/card/RessourceCard'
	import Dynamic from 'shared/components/utils/Dynamic'

	export default {
		name: 'ressources',
		components: {Dynamic, RessourceCard, LoadingContainer, HomepageCta, BrushBtn, Sprite, BlogCard},
		data: function () {
			return {
				loadMore: true,
				page: 1,
				regionId: '*',
				catId: '*',
				ressources: []
			}
		},
		computed: {
			pageData() {
				let pageId = this.$route.meta.id
				if (typeof this.$store.state.pages.pages[pageId] === 'undefined') {
					return null
				}
				return this.$store.state.pages.pages[pageId]
			},
			fields() {
				if (!this.pageData) return
				return this.pageData.fields
			},
			orderedRegions() {
				let regions = this.$store.state.data.regions.map(r => r)
				return regions.sort((a, b) => {
					if (a.title < b.title) return -1
					if (a.title > b.title) return 1
					return 0
				})
			}
		},
		metaInfo() {
			return {
				title: this.pageData.fields.seo.titleRaw[1]
			}
		},
		created() {
			if (!this.pageData) {
				this.$store.dispatch(types.LOAD_PAGE, this.$route.meta.id)
			}

			if (this.$store.state.ressources.ressources.length === 0) {
				this.filterRessources('*', '*')
			} else {
				this.ressourcesFiltered()
			}
		},
		methods: {
			changeRegion(e) {
				this.filterRessources(e.target.value, this.catId)
			},
			changeCategory(e) {
				this.filterRessources(this.regionId, e.target.value)
			},
			ressourcesFiltered() {
				if (this.regionId === '*' && this.catId === '*') {
					this.ressources = this.$store.state.ressources.ressources
					return
				}


				this.ressources = this.$store.state.ressources.ressources.filter(ressource => {
					let isOk = []
					if (this.regionId !== '*') {
						isOk.push(ressource.regionId === this.regionId)
					}

					if (this.catId !== '*') {
						isOk.push(ressource.catIds.includes(this.catId))
					}
					return !isOk.includes(false)
				})
			},
			loadMoreBtn() {
				this.page += 1
				this.filterRessources(this.regionId, this.catId, false)
			},
			filterRessources(regionId, categoryId, makeEmptyLoading = true) {
				this.regionId = regionId
				this.catId = categoryId

				if (regionId === '*') {
					regionId = ''//for GET request
				}
				if (categoryId === '*') {
					categoryId = ''//for GET request
				}

				//console.log(makeEmptyLoading)
				if (makeEmptyLoading) {
					clearTimeout(this.timeoutId)
					this.timeoutId = setTimeout(() => {
						this.ressources = []
					}, 300)
				}

				this.$store.dispatch(types.START_LOADER, 'ressources')
				this.$store.dispatch(types.LOAD_RESSOURCES, {
					regionId: regionId,
					page: this.page,
					categoryId: categoryId
				})
					.then((data) => {
						clearTimeout(this.timeoutId)
						this.loadMore = data.meta.pagination.total_pages !== this.page
						this.page = data.meta.pagination.current_page
						this.$store.dispatch(types.STOP_LOADER, 'ressources')
						this.ressourcesFiltered()
					})
					.catch(err => {
						console.log(err)
					})
			}
		}
	}
</script>
