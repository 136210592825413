import Vue from 'vue'
import Vuex from 'vuex'
import pages from './modules/pages'
import user from './modules/user'
import news from './modules/news'
import general from './modules/general'
import data from './modules/data'
import ressources from './modules/ressources'

Vue.use(Vuex)

// Store is where all the actions, and data are stored (save)
const store = new Vuex.Store({
	modules: {
		data:data,
		pages: pages,
		user: user,
		news: news,
		general: general,
		ressources: ressources
	},
	strict: process.env.NODE_ENV !== 'production'
})


export default store
