<template>

	<section id="blog-page">

		<div class="page-header mb-large">

			<div class="container-fluid">
				<div class="row start-xs">
					<div class="col-xxs-12 col-sm-10 col-lg-8 page-header--title">
						<dynamic v-if="fields" :template="fields.body"></dynamic>
					</div>
				</div>
			</div>

		</div>

		<div class="container-fluid">
			<div class="row start-xs">
				<h4 class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 font-size-14">
					Filtrer les articles
				</h4>

				<div class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xlg-12 filters">
					<ul class="flex flex--wrap flex--justify-start">
						<li>
							<a @click.prevent="filterNews('*')" class="filter-handle"
							   :class="{'active': categoryId === '*'}">Tous</a>
						</li>
						<li v-for="category in $store.state.news.categories" :key="category.id">
							<a @click.prevent="filterNews(category.id)" class="filter-handle"
							   :class="{'active': categoryId === category.id}">{{ category.title }}</a>
						</li>
					</ul>

					<span class="line">
						<sprite id="line-horizontal-xxlarge"></sprite>
					</span>
				</div>
			</div>
		</div>

		<div class="container-fluid">
			<loading-container loader-name="news">
			</loading-container>
			<div class="row start-xs" id="filter-items"
				 :class="{'loading': $store.state.general.loaderName.includes('news')}"
			>
				<div
					v-for="article in news"
					:key="article.id"
					class="col-xxs-12 col-xs-12 col-sm-10 col-md-4 col-lg-4 mt-medium article-wrapper"
					:class="{'loading': $store.state.general.loaderName.includes('news')}"
				>
					<blog-card :article="article"></blog-card>
				</div>
			</div>


			<div class="row center-xs pagination" v-if="loadMore">
				<div class="col-xxs-12 col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xlg-6 mt-medium">
					<button
						@click.prevent="loadMoreBtn"
						type="button" class="btn btn-default-brush next load-more">
						<brush-btn type="btn-xxlarge">
							<span>Afficher plus de résultats</span>
						</brush-btn>
					</button>
				</div>
			</div>
		</div>

		<homepage-cta></homepage-cta>

	</section>


</template>
<script>
	import BlogCard from 'site/components/atoms/card/BlogCard'
	import Sprite from 'shared/components/utils/Sprite'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	import LoadingContainer from 'shared/components/container/LoadingContainer'
	import types from 'site/store/types'
	import Dynamic from 'shared/components/utils/Dynamic'

	export default {
		name: 'blogue',
		components: {Dynamic, LoadingContainer, HomepageCta, BrushBtn, Sprite, BlogCard},
		data: function () {
			return {
				loadMore: true,
				page: 1,
				categoryId: '*',
				news: [],
			}
		},
		computed: {
			pageData() {
				let pageId = this.$route.meta.id
				if (typeof this.$store.state.pages.pages[pageId] === 'undefined') {
					return null
				}
				return this.$store.state.pages.pages[pageId]
			},
			fields() {
				if (!this.pageData) return null
				return this.pageData.fields
			},
			images() {
				if (!this.fields) return null
				return this.fields.responsiveImage
			}
		},
		created() {
			if (!this.pageData) {
				this.$store.dispatch(types.LOAD_PAGE, this.$route.meta.id)
			}

			if (this.$store.state.news.news.length === 0) {
				this.$router.push({ name: 'temporary'})
			} else {
				this.newsFiltered();
			}
		},
		methods: {
			newsFiltered() {
				if (this.categoryId === '*') {
					this.news = this.$store.state.news.news
					return
				}
				this.news = this.$store.state.news.news.filter(article => article.catIds.includes(this.categoryId))
			},
			loadMoreBtn() {
				this.page += 1
				this.filterNews(this.categoryId)
			},
			filterNews(categoryId) {
				this.categoryId = categoryId

				if (categoryId === '*') {
					categoryId = ''//for GET request
				}
				let timeoutId = setTimeout(() => {
					this.news = []
				}, 300)

				this.$store.dispatch(types.START_LOADER, 'news')
				this.$store.dispatch(types.LOAD_NEWS, {categoryId: categoryId, page: this.page})
					.then((data) => {
						clearTimeout(timeoutId)
						this.loadMore = data.meta.pagination.total_pages !== this.page
						this.page = data.meta.pagination.current_page
						this.$store.dispatch(types.STOP_LOADER, 'news')
						this.newsFiltered();
					})
					.catch(err => {
						console.log(err)
					})
			}
		}
	}
</script>
