import types from 'site/store/types'
import ajax from 'shared/utils/ajax'
//Static

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
	ressources: initData.ressources || [],
	categories: initData.ressourcesCategories
}


// getters, make function easy to access by vue
const getters = {
	getRessourceCategoryById: state => id => state.categories.find(cat => cat.id === id)
}

// actions
const actions = {
	[types.LOAD_RESSOURCES](store, options) {
		return new Promise((resolve, reject) => {
			ajax({
				url: `/api/ressources.json?page=${encodeURI(options.page)}&regionId=${encodeURI(options.regionId)}&categoryId=${encodeURI(options.categoryId)}`,
				method: 'get'
			})
				.then(data => {
					store.commit(types.LOAD_RESSOURCES, data.data)
					return resolve(data)
				})
				.catch((err) => {
					reject()
				})
		})
	}
}

// mutations
const mutations = {
	[types.LOAD_RESSOURCES](state, ressources) {
		let newRessources = state.ressources
		ressources.forEach(newRessource => {
			let ressourceIdx = state.ressources.findIndex(article => article.id === newRessource.id)
			if (ressourceIdx !== -1) {
				newRessources.splice(ressourceIdx, 1)//remove old version
			}
			newRessources.push(newRessource)
		})

		state.ressources = newRessources
	}
}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
