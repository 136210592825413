<template>
	<article class="card card--blog">
		<div class="header">
			<img v-if="article.image" class="header-image" :src="article.image.url">

			<div class="header-hover">
				<div class="relative w-full h-full">
					<div class="date">
						<span data-date="" class="pill">
							{{ article.dateCreated|title }}
						</span>
					</div>

					<div class="author" v-if="article.author">
						<div class="author--content">
							<img v-if="article.author.photo" class="author--content-photo" :src="article.author.photo"/>
							<p class="author--content-name">{{article.author.name}}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<h3>{{ article.title }}</h3>
			<p v-html="article.description"></p>
		</div>
		<div class="footer">
			<underline-link :url="article.url" icon="arrow-right" class="read-more">
				Lire la suite
			</underline-link>
		</div>
	</article>

</template>

<script>
	import UnderlineLink from 'shared/components/atoms/UnderlineLink'
	export default {
		name: 'blog-card',
		components: {UnderlineLink},
		props: {
			article: {
				type: Object
			}
		}
	}
</script>
