import types from 'site/store/types'
import ajax from 'shared/utils/ajax'
//Static

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
	regions: initData.regions || [],
	ads: initData.ads || []
}


// getters, make function easy to access by vue
const getters = {
	getRegionById: state => id => state.regions.find(r => r.id === id)
}

// actions
const actions = {}

// mutations
const mutations = {}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
