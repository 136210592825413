<template>
	<section class="blog">
		<div class="container-fluid">
			<page-heading
				title="Blogue"
				description="Accéder à des stratégies générales et à des trucs à mettre en application"
			></page-heading>
			<div class="row start-xs">

				<div class="col-xxs-12 col-xs-12 col-sm-6 col-md-4 col-lg-4"
					 v-for="article in $store.getters.mostRecentArticles(2)"
					 :key="article.id"
				>
					<blog-card :article="article"></blog-card>
				</div>

				<div class="col-xxs-12 col-xs-12 col-sm-6 col-md-4 col-lg-4">

					<basic-card namespace="blog">
						<img src="~shared/assets/images/home/blog.png" class=" h-xlarge w-auto mx-auto"/>

						<router-link to="/blogues"
									 class="text-align-center flex flex-row center-xs middle-xs round-btn-link">
							Consulter tous les articles
							<round-btn size="normal">
								<sprite id="arrow-right"></sprite>
							</round-btn>
						</router-link>
					</basic-card>
				</div>
			</div>
		</div>
	</section>

</template>
<script>
	import PageHeading from 'site/components/atoms/PageHeading'
	import BlogCard from 'site/components/atoms/card/BlogCard'
	import RoundBtn from 'shared/components/atoms/btns/RoundBtn'
	import Sprite from 'shared/components/utils/Sprite'
	import BasicCard from 'site/components/atoms/card/BasicCard'

	export default {
		name: 'section-blog',
		components: {BasicCard, Sprite, RoundBtn, BlogCard, PageHeading}
	}
</script>
