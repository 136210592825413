<template>
	<section id="about-page">

		<div class="page-header">
			<img class="w-full h-auto" src="~shared/assets/images/header-bg.png"/>
		</div>
		<div class="container-fluid about-page__content">
			<div class="row between-xs">
				<div class="col-xs-12 col-sm-6 col-md-7 col-lg-7">
					<builder-loop :builder="pageData.builder"></builder-loop>
				</div>
				<div class="col-xs-12 col-sm-5 col-md-4 col-lg-4 mt-0 xs:mt-large">
					<div class="references"  v-html="fields.body">
					</div>
				</div>
			</div>
		</div>

		<homepage-cta></homepage-cta>

	</section>
</template>
<script>
	import Sprite from 'shared/components/utils/Sprite'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	import LoadingContainer from 'shared/components/container/LoadingContainer'
	import types from 'site/store/types'
	import Dynamic from 'shared/components/utils/Dynamic'
	import BuilderLoop from 'shared/components/builder/BuilderLoop'

	export default {
		name: 'about',
		components: {HomepageCta, BuilderLoop},
		data: function () {
			return {}
		},
		computed: {
			pageData() {
				let pageId = this.$route.meta.id
				if (typeof this.$store.state.pages.pages[pageId] === 'undefined') {
					return null
				}
				return this.$store.state.pages.pages[pageId]
			},
			fields() {
				if (!this.pageData) return null
				return this.pageData.fields
			},
		},
		metaInfo() {
			return {
				title: this.pageData.fields.seo.titleRaw[1]
			}
		},
		created() {
			if (!this.pageData) {
				this.$store.dispatch(types.LOAD_PAGE, this.$route.meta.id)
			}
		}
	}
</script>
