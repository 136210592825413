<template>
	<div class="col-xs-12 col-md-8 image pb-medium">
		<img :src="image.url" :alt="image.title" v-if="image">
	</div>
</template>
<script>
	export default {
		name: 'section-continuum',
		props: ['image']
	}
</script>
