<template>
	<div class="col-xs-10 col-sm-7 col-md-7 col-lg-7 image centered">
		<img :src="image.url" :alt="image.title" v-if="image">
	</div>

</template>

<script>
	export default {
		name: 'section-strategies',
		props: ['image']
	}
</script>
