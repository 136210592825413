const LOAD_PAGE = 'LOAD_PAGE'
const PRELOAD_PAGES = 'PRELOAD_PAGES'
const LOAD_NEWS = 'LOAD_NEWS'
const LOAD_ARTICLE_BY_SLUG = 'LOAD_ARTICLE_BY_SLUG'
const LOAD_RESSOURCES = 'LOAD_RESSOURCES'

//Loader
const START_LOADER = 'START_LOADER'
const STOP_LOADER = 'STOP_LOADER'
const STOP_INITIAL_LOADER = 'STOP_INITIAL_LOADER'
const STOP_ALL_LOADER = 'STOP_ALL_LOADER'

export default {
	START_LOADER,
	STOP_LOADER,
	STOP_INITIAL_LOADER,
	STOP_ALL_LOADER,
	LOAD_RESSOURCES,
	LOAD_PAGE,
	LOAD_NEWS,
	LOAD_ARTICLE_BY_SLUG,
	PRELOAD_PAGES
}
