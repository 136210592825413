<template>
	<article class="card card--ressource">
		<div class="header">
			<img v-if="ressource.image" class="header-image" :src="ressource.image.url">

			<div class="header-hover">
				<div class="relative w-full h-full">
					<div class="region" v-if="regionTitle">
						<span class="pill">
							{{ regionTitle }}
						</span>
					</div>

					<div class="category-row ">
						<div class="category" v-for="categoryTitle in categoryTitles">
							<span class="pill">
								{{ categoryTitle }}
							</span>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="content">
			<h3>{{ ressource.title }}</h3>
			<p v-html="ressource.description"></p>
		</div>
		<div class="footer">
			<underline-link :external="true" :url="ressource.url" icon="arrow-right" class="read-more">
				Lien vers le site web
			</underline-link>
		</div>
	</article>

</template>

<script>
	import Sprite from 'shared/components/utils/Sprite'
	import UnderlineLink from 'shared/components/atoms/UnderlineLink'

	export default {
		name: 'ressource-card',
		components: {UnderlineLink, Sprite},
		props: {
			ressource: {
				type: Object
			}
		},
		computed: {
			regionTitle() {
				let region = this.$store.getters.getRegionById(this.ressource.regionId)
				if (!region) return null

				return region.title

			},
			categoryTitles() {
				return this.ressource.catIds.map(id => {
					let re = this.$store.getters.getRessourceCategoryById(id)
					return re ? re.title : ''
				})
			}
		}
	}
</script>
