import types from 'site/store/types'
import ajax from 'shared/utils/ajax'
//Static

// initial state, with craft backend or empty
const initData = JSON.parse(JSON.stringify(window.__initialData__))
const state = {
	news: initData.news || [],
	categories: initData.newsCategories
}


// getters, make function easy to access by vue
const getters = {
	mostRecentArticles: state => count => state.news.slice(0, count),
	getArticleBySlug: state => slug => state.news.find(article => article.slug === slug),
}

// actions
const actions = {
	[types.LOAD_ARTICLE_BY_SLUG](store, slug) {

		let existArticle = store.getters.getArticleBySlug(slug)
		if (existArticle) {
			return Promise.resolve(existArticle)
		}

		return new Promise((resolve, reject) => {
			ajax({
				url: `/actions/eclo-app-module/data/get-article?slug=${slug}`,
				method: 'get'
			})
				.then(data => {
					if (data.success) {
						store.commit(types.LOAD_NEWS, [data.article])
						return resolve(data.article)
					}
					reject()
				})
				.catch(() => {
					reject()
				})
		})
	},
	[types.LOAD_NEWS](store, options) {
		return new Promise((resolve, reject) => {
			ajax({
				url: `/api/news.json?page=${encodeURI(options.page)}&categoryId=${encodeURI(options.categoryId)}`,
				method: 'get'
			})
				.then(data => {
					store.commit(types.LOAD_NEWS, data.data)
					return resolve(data)
				})
				.catch((err) => {
					reject()
				})
		})
	}
}

// mutations
const mutations = {
	[types.LOAD_NEWS](state, news) {
		let newNews = state.news
		news.forEach(newArticle => {
			let articleIdx = state.news.findIndex(article => article.id === newArticle.id)
			if (articleIdx !== -1) {
				newNews.splice(articleIdx, 1)//remove old version
			}
			newNews.push(newArticle)
		})

		state.news = newNews
	}
}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
