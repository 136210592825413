<template>
	<div class="page-article">
		<loading-container loader-name="article">

			<section id="article-page">

				<div class="page-header">

					<div class="page-header--shape">
						<img class="w-full h-auto z--1" src="~shared/assets/svg/graphics/bg/bg-header-shape.svg"/>
					</div>
				</div>

				<transition name="fade" mode="out-in">
					<div class="container-fluid" v-if="article" :key="article.id">
						<div class="row start-xs mt-x-large">
							<div class="col-xxs-12 col-xs-12 col-sm-7 col-md-7 col-lg-7 col-xlg-7">

								<h1 class="mb-x-small">{{ article.heading }}</h1>
								<p class="text-blue font-weight-normal mb-small">{{article.subtitle}}</p>
								<article class="card card--blog card--header">
									<div class="header">
										<img v-if="article.article" class="header-image" :src="article.image.url">
										<div class="header-hover">
											<div class="relative w-full h-full">
												<div class="date">
													<span class="pill">
														{{ article.dateCreated|title }}
													</span>
												</div>

												<div class="author" v-if="article.author">
													<div class="author--content">
														<img v-if="article.author.photo"
															 class="author--content-photo"
															 :src="article.author.photo"/>
														<p class="author--content-name">{{article.author.name}}</p>
													</div>
												</div>

											</div>
										</div>
									</div>
								</article>

								<div class="content mt-large">
									<builder-loop :builder="article.builder"></builder-loop>
								</div>
							</div>

							<aside
								class="col-xxs-12 col-xs-12 col-sm-4 col-md-4 col-lg-4 col-xlg-4 col-sm-offset-1 col-md-offset-1 col-lg-offset-1 col-xlg-offset-1">
								<h4 class="text-blue font-size-14 block">Autres articles pertinents</h4>


								<div class="row start-xs">

									<div
										v-for="article in otherNews"
										:key="article.id"
										class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-medium">
										<blog-card :article="article"></blog-card>
									</div>

								</div>


								<div class="row start-xs mt-medium article-ad" v-if="SITE_AD_IMAGE_URL">
									<div class="col-xxs-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
										<h4 class="text-blue font-size-14 block">Publicité</h4>
										<div class="border-radius-normal mt-medium">
											<img class="w-full h-auto z--1" :src="SITE_AD_IMAGE_URL"/>
										</div>
									</div>
								</div>

							</aside>
						</div>
						<div class="mt-large">
							<underline-link color="blue" url="/blogue" icon="arrow-left" icon-position="left">
								Retour au blogue
							</underline-link>
						</div>

					</div>
				</transition>


			</section>
		</loading-container>
		<homepage-cta></homepage-cta>
	</div>
</template>

<script>
	import BuilderLoop from 'shared/components/builder/BuilderLoop'
	import BlogCard from 'site/components/atoms/card/BlogCard'
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	import Sprite from 'shared/components/utils/Sprite'
	import types from 'site/store/types'
	import LoadingContainer from 'shared/components/container/LoadingContainer'
	import UnderlineLink from 'shared/components/atoms/UnderlineLink'

	export default {
		name: 'single-article',
		components: {UnderlineLink, LoadingContainer, Sprite, HomepageCta, BlogCard, BuilderLoop},
		data: function () {
			return {
				article: null
			}
		},
		watch: {
			'$route'() {
				this.fetchArticle()
			}
		},
		created() {
			this.fetchArticle()
		},
		methods: {
			fetchArticle() {
				this.article = null
				this.$store.dispatch(types.START_LOADER, 'article')
				this.$store.dispatch(types.LOAD_ARTICLE_BY_SLUG, this.$route.params.articleSlug)
					.then(article => {
						this.$store.dispatch(types.STOP_LOADER, 'article')
						this.article = article
					})
					.catch(() => {
						this.$store.dispatch(types.STOP_LOADER, 'article')
						this.$router.push('/')
					})
			}
		},
		computed: {
			otherNews() {
				if (!this.article) return []
				let others = this.$store.state.news.news.filter(article => article.id !== this.article.id)
				return others.slice(0, 2)
			}
		}
	}
</script>
