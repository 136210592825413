<template>
	<div class="col-xs-12 col-md-10 image">
	<img :src="image.url" :alt="image.title" v-if="image">
</div>

</template>
<script>
	export default {
		name: 'section-questionnaire',
		props: ['image']
	}
</script>
