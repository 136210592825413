import merge from 'shared/utils/merge'
import types from 'app/store/types'
import {routes, dynamicRoutes} from 'app/config/routes'
import ajax from 'shared/utils/ajax'
import apiActions from 'app/config/apiActions'
import {isEmpty} from 'shared/utils/utils'
//Static

// initial state, with craft backend or empty
const state = {
	currentUser: window.__initialData__.currentUser ? JSON.parse(JSON.stringify(window.__initialData__.currentUser)) : null,
	errors: [],
	validationErrors: {}
}

//if already login
if (window.__initialData__.currentUser) {
	if (window.Sentry) {
		window.Sentry.configureScope((scope) => {
			scope.setUser({
				email: window.__initialData__.currentUser.email,
				id: window.__initialData__.currentUser.id
			})
		})
	}
}

// getters, make function easy to access by vue
const getters = {
	currentUserFullName: state => {
		if (!state.currentUser) return ''

		return state.currentUser.firstName + ' ' + state.currentUser.lastName
	}
}

// actions
const actions = {

}

// mutations
const mutations = {}

export default {
	namespaced: false,
	state,
	getters,
	actions,
	mutations
}
