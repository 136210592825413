<template>
	<section id="front-page">
		<div class="page-header-container">
			<div class="page-header">
				<img v-if="images.desktopImage.title" class="w-full h-auto z--1 page-header--image mobile-hide"
					 :src="images.desktopImage.url"/>
				<img v-if="images.mobileImage.title" class="w-full h-auto z--1 page-header--image desktop-hide"
					 :src="images.mobileImage.url"/>
			</div>

			<div class="container-fluid">
				<div class="text-align-center page-header--title">
					<dynamic :template="fields.body"></dynamic>
				</div>
			</div>

			<div class="mx-auto text-align-center transform-50 mt-medium relative z-10 button-wrapper">
				<a class="btn btn-default-brush" :href="`${ECLO_APP_URL}/`">
					<brush-btn type="btn-xxlarge">
						Accéder à l’information
						<sprite id="arrow-right"></sprite>
					</brush-btn>
				</a>
			</div>
		</div>

		<section
			v-for="section in fields.homepageBuilder"
			:key="section.identifier"
			class="section"
			:class="[`homepage-block--${section.identifier}`, section.classes]"
		>
			<div class="section-border section-border--top">

			</div>
			<div class="container-fluid home-item">
				<div class="row middle-xs">
					<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 py-normal">

						<div class="title-content" :class="section.classes">
							<h5 class="mb-x-small">{{ section.supheading }}</h5>
							<h2 class="mb-small">{{ section.heading }}</h2>
							<div v-html="section.body"></div>

							<span
								v-if="section.tag"
								class="price"
							>
								<span>{{ section.tag }}</span>
							</span>
						</div>

					</div>
					<div class="col-xs-12 col-sm-6 col-md-8 col-lg-8">
						<div class="row">
							<component :is="getImageForSection(section.identifier)"
									   :image="section.image"
							></component>
						</div>
					</div>
				</div>
			</div>
			<div class="section-border section-border--bottom">

			</div>
		</section>


		<homepage-cta></homepage-cta>

		<!--
		<section-blog></section-blog>
		<section-ressources></section-ressources>
		-->

	</section>
</template>

<script>
	import Vue from 'vue'
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import Sprite from 'shared/components/utils/Sprite'
	import SectionCompte from 'site/components/pages/homepage-sections/Compte'
	import SectionContinuum from 'site/components/pages/homepage-sections/Continuum'
	import SectionQuestionnaire from 'site/components/pages/homepage-sections/Questionnaire'
	import SectionStrategies from 'site/components/pages/homepage-sections/Strategies'
	import SectionRessources from 'site/components/pages/homepage-sections/Ressources'
	import SectionBlog from 'site/components/pages/homepage-sections/Blog'
	import HomepageCta from 'site/components/pages/homepage-sections/CTA'
	import UnderlineGreenBrush from 'shared/components/utils/UnderlineGreenBrush'
	import Dynamic from 'shared/components/utils/Dynamic'

	export default {
		name: 'homepage',
		components: {Dynamic, HomepageCta, SectionBlog, SectionRessources, Sprite, BrushBtn, UnderlineGreenBrush},
		computed: {
			pageData() {
				let pageId = this.$route.meta.id
				if (typeof this.$store.state.pages.pages[pageId] === 'undefined') {
					return null
				}
				return this.$store.state.pages.pages[pageId]
			},
			fields() {
				return this.pageData.fields
			},
			images() {
				return this.fields.responsiveImage
			}
		},
		metaInfo() {
			return {
				title: this.pageData.fields.seo.titleRaw[1]
			}
		},
		created() {
			if (!this.pageData) {
				this.$store.dispatch(types.LOAD_PAGE, this.$router.currentRoute.meta.id)
			}
		},
		methods: {
			getImageForSection(id) {
				switch (id) {
					case 'compte':
						return SectionCompte
					case 'continuum':
						return SectionContinuum
					case 'questionnaire':
						return SectionQuestionnaire
					case 'strategies':
						return SectionStrategies
				}


			}
		}
	}
</script>
