<template>
	<div class="home-image--compte ">
		<div class="flex flex-row flex-wrap center-xs">
			<div class="col-xs-5 col-sm-6 col-md-4 col-lg-4 no-gutter">
				<img class="w-full kids block" :src="image.url" :title="image.title" v-if="image"/>
			</div>
			<div class="col-xs-11 col-sm-10 col-md-8 col-lg-6 no-gutter">
				<div class="kids">

					<div class="children isFirst flex flex--wrap flex--justify-between flex--align-center">
						<div class="flex flex-row middle-xs">
							<div class="children--initial w-large mx-medium">
								<round-btn>
									<span>A</span>
								</round-btn>
							</div>
							<div class="children--details">
								<h3>Antoine</h3>
								<h6>4 ans</h6>
								<div class="mt-small">
									<p>Questionnaire réalisé il y a 3 mois</p>
								</div>
							</div>
						</div>
					</div>
					<div class="children flex flex--wrap flex--justify-between flex--align-center">
						<div class="flex flex-row middle-xs">
							<div class="children--initial w-large mx-medium">
								<round-btn color="pink">
									<span>MC</span>
								</round-btn>
							</div>
							<div class="children--details">
								<h3>Marie-Catherine</h3>
								<h6>2 ans</h6>
								<div class="mt-small">
									<p>Questionnaire réalisé il y a 9 mois</p>
								</div>
							</div>
						</div>
					</div>
					<div class="children isLast flex flex--wrap flex--justify-between flex--align-center">
						<div class="flex flex-row middle-xs">
							<div class="children--initial w-large mx-medium">
								<round-btn color="blue">
									<span>J</span>
								</round-btn>
							</div>
							<div class="children--details">
								<h3>Jules</h3>
								<h6>5 ans</h6>
								<div class="mt-small">
									<p>Questionnaire réalisé il y a 6 mois</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>


</template>

<script>
	import RoundBtn from 'shared/components/atoms/btns/RoundBtn'

	export default {
		name: 'section-compte',
		components: {RoundBtn},
		props: ['image']
	}
</script>
