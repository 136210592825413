<template>
	<article class="card" :class="`card--${namespace}`">
		<div class="header" v-if="$slots.header">
			<slot name="header"></slot>
		</div>

		<div class="content">
			<slot></slot>
		</div>

		<div class="footer" v-if="$slots.footer">
			<slot name="footer"></slot>
		</div>

	</article>
</template>

<script>
	export default {
		name: 'basic-card',
		props: {
			namespace: {
				type: String,
				default: 'default'
			},
		}
	}
</script>
