<template>
	<div
		v-if="pageData"
		:key="$route.meta.id"
	>
		<builder-loop :builder="pageData.builder"></builder-loop>
	</div>
</template>

<script>
	import BuilderLoop from 'shared/components/builder/BuilderLoop'

	export default {
		name: 'builder-page',
		components: {
			BuilderLoop
		},
		computed: {
			pageData() {
				let pageId = this.$route.meta.id
				if (typeof this.$store.state.pages.pages[pageId] === 'undefined') {
					return null
				}
				return this.$store.state.pages.pages[pageId]
			}
		},
		created() {
			if (!this.pageData) {
				this.$store.dispatch(types.LOAD_PAGE, this.$router.currentRoute.meta.id)
			}

		}
	}
</script>
