<template>
	<section class="ressources mb-normal mt-x-large">
		<div class="container-fluid">
			<div class="row start-xs">
				<div class="col-xs-12 col-sm-8 col-md-4 col-lg-4">
					<h1 class="text-blue mb-x-small">Ressources</h1>
					<p>Un botin pour tous ceux à la recherche d’aide dans le domaine de l’orthophonie.</p>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 ressources--item">
					<h3>Cliniques</h3>
					<ul class="flex flex--wrap flex--justify-between">
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Trouver une clinique
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Guide du parent
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Guide du parent
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Trouver une clinique
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
					</ul>
				</div>
				<div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 ressources--item">
					<h3>Parents</h3>
					<ul class="flex flex--wrap flex--justify-between">
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Trouver une clinique
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Guide du parent
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Guide du parent
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
						<li class="mt-small width-50">
							<a href="#" class="btn btn-text font-size-14 text-blue">
								<span>
									Trouver une clinique
								</span>
								<span class="icon">
									<sprite id="arrow-right"></sprite>
								</span>
								<span class="line">
									<sprite id="line-horizontal-normal"></sprite>
								</span>
							</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</section>

</template>

<script>
	import Sprite from 'shared/components/utils/Sprite'

	export default {
		name: 'section-ressources',
		components: {Sprite}
	}
</script>
