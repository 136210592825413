<template>
	<section class="cta bg-white">
		<div class="container-fluid">
			<div class="row middle-xs center-xs">
				<div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 col">

					<div class="trees-left mobile-hide">
						<img src="~shared/assets/images/branchs/trees-left.png"/>
					</div>
					<div class="trees-right mobile-hide">
						<img src="~shared/assets/images/branchs/trees-right.png"/>
					</div>
					<div class="kids-jumping mobile-hide">
						<img src="~shared/assets/images/kids/kids-jumping.png"/>
					</div>

					<div class="col-xs-12 col-sm-8 col-md-8 col-lg-8 mx-auto no-padding no-gutter">
						<h2 class="mb-small text-align-center">
							De l’information fiable sur le développement du langage et les stratégies de stimulation
            </h2>

						<div class="desktop-hide w-full">
							<img src="~shared/assets/images/home/cta-mobile.png"/>
						</div>

						<div class="container-btn w-full">
							<a class="btn btn-default-brush w-auto xxs:w-full"
							   :href="`${ECLO_APP_URL}/`">
								<brush-btn type="btn-xxlarge">
									Accéder à l’information
									<sprite id="arrow-right"></sprite>
								</brush-btn>
							</a>

						</div>
					</div>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
	import BrushBtn from 'shared/components/atoms/btns/BrushBtn'
	import Sprite from 'shared/components/utils/Sprite'
	export default {
		name: 'homepage-cta',
		components: {Sprite, BrushBtn}
	}
</script>
