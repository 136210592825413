import Homepage from 'site/components/pages/Homepage'
import ErrorPage from 'shared/components/pages/ErrorPage'
import PageNotReady from 'site/components/pages/PageNotReady'
import BuilderPage from 'site/components/pages/BuilderPage'
import NewsPage from 'site/components/pages/News'
import AboutPage from 'site/components/pages/About'
import SingleArticle from 'site/components/pages/SingleArticle'
import Ressources from 'site/components/pages/Ressources'


let vueRoutes = []

const getComponents = (slug) => {
	switch (slug) {
		case 'homepage':
			return Homepage
		case 'blogue':
			return NewsPage
		case 'a-propos':
			return AboutPage
		case 'ressources':
			return Ressources
		default:
			return ErrorPage
	}
}

Object.keys(window.__initialData__.routes).forEach(pageId => {
	let pageData = window.__initialData__.routes[pageId]
	vueRoutes.push({
		name: pageData.slug,
		component: getComponents(pageData.slug),
		path: pageData.path,
		meta: {
			id: pageData.id
		}
	})
})

//add last route
vueRoutes = vueRoutes.concat([
	{
		name: 'article',
		path: '/articles/:articleSlug',
		component: SingleArticle
	},
	{
		name: 'temporary',
		path: '/page-temporaire',
		component: PageNotReady
	},
	{
		name: '*',
		path: '*',
		redirect: '/404'
	},
	{
		name: '404',
		path: '/404',
		component: ErrorPage
	}
])

export default vueRoutes
